// App.js
import React from "react";
import "./index.css";
import Navbar from "./components/Navbar/Navbar";

import { Routes, Route } from "react-router-dom";
import Home from "./components/Pages/Home";
import Footer from "./components/Footer/Footer";
import Service from "./components/Service/Service";
import { MyBooking } from "./components/Pages/MyBooking";
import Packing from "./components/Service/Packing";
import OfficeShiftingServices from "./components/Service/OfficeShiftingServices";
import CarandBikeTransportation from "./components/Service/CarandBikeTransportation";
import DomesticShiftingServices from "./components/Service/DomesticShiftingServices";
import WarehouseServices from "./components/Service/WarehouseServices";
import HouseShiftingServices from "./components/Service/HouseShiftingServices";
import LocalHouseholdShifting from "./components/Service/LocalHouseholdShifting";
import Policy from "./components/Pages/Policy";
import RefundPolicy from "./components/Pages/RefundPolicy";
import Terms from "./components/Pages/Terms";
import AboutUs from "./components/Pages/AboutUs";

const App = () => {
  return (
    <div className="app-main">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/form" element={<MyBooking />} />
        <Route path="/services" element={<Service />} />
        <Route path="/packing-services" element={<Packing />} />
        <Route
          path="/carandBikeTransportation"
          element={<CarandBikeTransportation />}
        />
        <Route
          path="/officeShiftingServices"
          element={<OfficeShiftingServices />}
        />
        <Route path="/warehouseServices" element={<WarehouseServices />} />
        <Route
          path="/domesticShiftingServices"
          element={<DomesticShiftingServices />}
        />
        <Route
          path="/HouseShiftingServices"
          element={<HouseShiftingServices />}
        />
        <Route
          path="/localHouseholdShifting"
          element={<LocalHouseholdShifting />}
        />
        <Route path="/RefundPolicy" element={<RefundPolicy />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/AboutUs" element={<AboutUs />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
