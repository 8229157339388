import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import deliver from "../Assets/full-shot.webp";
import deliver2 from "../Assets/domestic.webp";
import { Link } from "react-router-dom";
import Form from "../Form/Form";
const HouseShiftingServices = () => {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="corporate-banner-loading">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>House Shifting Services</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link>Service</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details-area">
        <div className="container">
          <div className="row mt-3">
            <div className="col-lg-7 mt-3">
              <div className="service-details-wrap">
                <h2>The Best House Shifting Services In Bangalore</h2>

                <p className="text-justify">
                Experience the best house shifting services in Bangalore with Bangalore Packers and Movers. Our team ensures a smooth, hassle-free relocation, handling your belongings with utmost care. From packing to unpacking, we provide comprehensive solutions tailored to your needs. Trust us for a seamless move and enjoy a stress-free transition to your new home. Choose Bangalore Packers and Movers for excellence in house shifting.
                </p>

                <p className="text-justify">
                Experience the best house shifting services in Bangalore with Bangalore Packers and Movers. Our expert team ensures a smooth, hassle-free relocation, handling your belongings with utmost care. From meticulous packing and secure transportation to efficient unpacking, we provide comprehensive solutions tailored to your needs. Our advanced equipment and trained staff guarantee the safety of your items throughout the move. Trust us for a seamless transition to your new home. Choose Bangalore Packers and Movers for excellence, reliability, and peace of mind in house shifting.
                </p>

                <p className="text-justify">
                Experience the best house shifting services in Bangalore with Bangalore Packers and Movers. Our expert team ensures a smooth, hassle-free relocation, handling your belongings with utmost care. From meticulous packing and secure transportation to efficient unpacking, we provide comprehensive solutions tailored to your needs. Our advanced equipment and trained staff guarantee the safety of your items throughout the move. We offer personalized services, accommodating special requests and ensuring timely delivery. Trust us for a seamless transition to your new home. Choose Bangalore Packers and Movers for excellence, reliability, and peace of mind in house shifting.
                </p>

                <div className="single-item">
                  <img
                    src={deliver}
                    alt="car bike transportation services in bangalore"
                  />
                </div>

                <h2 className="mt-4">
                  What Are The Benefits of Hiring An Expert?
                </h2>

                <p className="text-justify">
                Hiring an expert ensures a stress-free relocation. Professionals handle packing, loading, and transportation with precision, saving you time and effort. They use quality materials to protect your belongings, offer insurance coverage, and provide efficient, organized services. With expert movers, you experience a smooth, safe, and hassle-free move, allowing you to focus on settling into your new home.
                </p>

                <p className="text-justify">
                Hiring an expert ensures a stress-free relocation. Professionals handle packing, loading, and transportation with precision, saving you time and effort. They use high-quality materials to protect your belongings, offer insurance coverage for added peace of mind, and provide efficient, organized services. With expert movers, you experience a smooth, safe, and hassle-free move, allowing you to focus on settling into your new home. Additionally, they have the necessary equipment and expertise to manage heavy and delicate items, ensuring everything arrives intact.
                </p>

                <p className="text-justify">
                Hiring an expert ensures a stress-free relocation. Professionals handle packing, loading, and transportation with precision, saving you valuable time and effort. They use high-quality materials to protect your belongings, offer insurance coverage for added peace of mind, and provide efficient, organized services. With expert movers, you experience a smooth, safe, and hassle-free move, allowing you to focus on settling into your new home. Additionally, they have the necessary equipment and expertise to manage heavy and delicate items, ensuring everything arrives intact. Experts also navigate logistical challenges and provide timely delivery, enhancing your overall moving experience.
                </p>

                <h2>Why Choose Us?</h2>
                <p className="text-justify">
                Choose Bangalore Packers and Movers for a seamless relocation experience. Our expert team ensures safe packing, efficient moving, and timely delivery. With years of experience and a commitment to customer satisfaction, we provide reliable and affordable services tailored to your needs. Trust us to handle your belongings with care, making your move stress-free and smooth. Your satisfaction is our priority.
                </p>
                <p className="text-justify">
                Choose Bangalore Packers and Movers for a hassle-free relocation. Our skilled team ensures safe packing, efficient loading, and timely delivery. With over a decade of experience, we guarantee top-notch service and customer satisfaction. We offer competitive pricing and personalized solutions for all your moving needs. Trust us to handle your belongings with utmost care and professionalism, ensuring a smooth and stress-free move every time.
                </p>
                <h2>Experience the Difference:</h2>
                <p className="text-justify">
                At Bangalore Packers and Movers, we go beyond the basics to ensure a seamless relocation experience. Our dedicated team uses advanced packing techniques and high-quality materials to safeguard your belongings. With a customer-centric approach, timely delivery, and comprehensive insurance, we provide peace of mind throughout your move. Trust us to make your relocation smooth, efficient, and stress-free. Experience the difference today!
                </p>
                <h5>
                  Efficiency, Reliability, Peace of Mind - That's Bangalore
                  Packers And Movers for You!
                </h5>
              </div>
              <div className="service-sidebar-area m-3 text-center">
                <div className="service-list service-card">
                  <div className="left-main-div">
                    <h3 className="service-details-title">Our Services</h3>
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Local Household Shifting
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Domestic Shifting Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        International Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Car & Bike Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Warehouse Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Packing Unpacking Services
                      </li>
                    </ul>
                  </div>
                  <div className="service-image">
                    <img src={deliver2} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-3">
              <Form activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HouseShiftingServices;
