import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import deliver from "../Assets/delivery-man.webp";
import deliver2 from "../Assets/icon1.webp";
import { Link } from "react-router-dom";
import Form from "../Form/Form";
const LocalHouseholdShifting = () => {
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="local-banner-loading">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>Local Household Shifting</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link>Service</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details-area">
        <div className="container">
          <div className="row mt-3">
            <div className="col-lg-7 mt-3">
              <div className="service-details-wrap">
                <h2>Best Local Household Shifting Services In Bangalore</h2>

                <p className="text-justify">
                  Welcome to Bangalore Packers and Movers! We specialize in
                  local household shifting, ensuring a seamless and stress-free
                  experience. Our professional team handles everything from
                  packing, loading, transporting, to unpacking, using
                  top-quality materials and equipment. With years of expertise,
                  we guarantee the safe and timely relocation of your belongings
                  within Bangalore. Choose us for a hassle-free move and
                  unparalleled service. Contact us today for a free quote and
                  let us make your move smooth and efficient!
                </p>

                <p className="text-justify">
                  For the best local household shifting services in Bangalore,
                  consider Bangalore Packers And Movers. They offer reliable
                  packing and moving solutions tailored to your needs. Their
                  services include packing, loading, transportation, unloading,
                  and unpacking with utmost care and efficiency. With a
                  commitment to customer satisfaction, Bangalore Packers And
                  Movers ensure a smooth relocation experience within Bangalore
                  and surrounding areas. Visit their website for more details
                  and to request a quote.
                </p>

                <p className="text-justify">
                  Bangalore Packers And Movers specialize in local household
                  shifting services across Bangalore, providing seamless
                  relocation solutions. Their experienced team handles packing
                  delicate items, loading securely, transporting safely, and
                  unpacking efficiently. With a focus on customer convenience,
                  they offer competitive pricing and ensure timely delivery.
                  Whether moving within the city or nearby areas, Bangalore
                  Packers And Movers guarantee a hassle-free experience backed
                  by professional service and reliability. Visit their website
                  today to explore their comprehensive range of moving services
                  and get a personalized quote.
                </p>

                <div className="single-item">
                  <img
                    src={deliver}
                    alt="car bike transportation services in bangalore"
                  />
                </div>

                <h2 className="mt-4">
                  The Most Secure & Reliable Packers & Movers In Bangalore
                </h2>

                <p className="text-justify">
                  Looking for the most secure and reliable packers and movers in
                  Bangalore? Bangalore Packers And Movers offer top-notch
                  services for all your relocation needs. With expert handling,
                  safe transportation, and timely delivery, we ensure a
                  hassle-free move. Whether it's local or long-distance, trust
                  us for professional and efficient service. Get a free quote
                  today and experience a stress-free relocation with the best in
                  Bangalore.
                </p>

                <p className="text-justify">
                  Looking for the most secure and reliable packers and movers in
                  Bangalore? Bangalore Packers And Movers offer top-notch
                  services for all your relocation needs. Our experienced team
                  ensures careful handling of your belongings, using
                  high-quality packing materials for maximum protection. We
                  provide safe and timely transportation, whether it's local or
                  long-distance. Our customer-centric approach guarantees a
                  hassle-free experience from start to finish. Trust us for
                  efficient, professional service and competitive pricing. Get a
                  free quote today and discover why we're the preferred choice
                  for countless satisfied customers in Bangalore. Move with
                  confidence, move with us.
                </p>

                <p className="text-justify">
                  We also offer specialized services for office relocation,
                  vehicle transportation, and warehousing. Our 24/7 customer
                  support is always ready to assist you. With Bangalore Packers
                  And Movers, your move is in safe hands. Experience the
                  difference with our comprehensive and personalized moving
                  solutions tailored to meet your specific requirements.
                </p>

                <h2>Why Choose Us?</h2>
                <p className="text-justify">
                  When it comes to relocating, Bangalore Packers and Movers
                  stands out for our commitment to customer satisfaction,
                  punctuality, and safety. We offer comprehensive moving
                  solutions tailored to your needs, whether residential or
                  commercial. Our skilled team handles your belongings with the
                  utmost care, ensuring a smooth and hassle-free transition.
                  With competitive pricing, reliable service, and a proven track
                  record, choosing us means choosing peace of mind for your
                  move.
                </p>
                <p className="text-justify">
                  Choosing Bangalore Packers and Movers ensures a seamless and
                  stress-free moving experience. Our team of experienced
                  professionals is dedicated to handling your belongings with
                  the utmost care, from packing to transportation and unpacking.
                  We use high-quality packing materials and state-of-the-art
                  equipment to ensure the safety of your items. Our transparent
                  pricing, punctuality, and personalized services set us apart
                  in the industry. We cater to both residential and commercial
                  moves, offering customized solutions to meet your specific
                  needs. Trust us to make your relocation smooth, efficient, and
                  worry-free, ensuring your complete satisfaction.
                </p>
                <h2>Experience the Difference:</h2>
                <p className="text-justify">
                  Experience the Difference with Bangalore Packers and Movers!
                  Our expert team ensures a seamless relocation experience,
                  handling your belongings with utmost care. With years of
                  experience, advanced equipment, and a customer-first approach,
                  we guarantee a stress-free move. From packing to
                  transportation and unpacking, we offer comprehensive services
                  tailored to your needs. Choose us for reliability, efficiency,
                  and peace of mind. Trust Bangalore Packers and Movers for your
                  next move!
                </p>
                <h5>
                  Efficiency, Reliability, Peace of Mind - That's Bangalore
                  Packers And Movers for You!
                </h5>
              </div>
              <div className="service-sidebar-area m-3 text-center">
                <div className="service-list service-card">
                  <div className="left-main-div">
                    <h3 className="service-details-title">Our Services</h3>

                    <ul>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Local Household Shifting
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Domestic Shifting Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        International Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Car & Bike Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Warehouse Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Packing Unpacking Services
                      </li>
                    </ul>
                  </div>
                  <div className="service-image">
                    <img src={deliver2} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-3">
              <Form activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LocalHouseholdShifting;
