import React from "react";
import Slider from "react-slick";
import { FaStar } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prof from "../Assets/prof.webp"
import { useEffect } from "react";

const Testimonial = () => {
  const testimonials = [
    {
      img: prof,
      name: "Deepak Kumar",
      rating: 5,
      text: "Experience amazing service... with Bangalore Packers And Movers! Their excellent staff will ensure a stress-free move for you. They are experts in good quality, waterproof packing, ensuring the safety of your belongings. Incredibly efficient, they packed our items in less than an hour, surpassing our expectations. And the best part? Their rates are affordable! We highly recommend their services.. for a smooth and affordable relocation experience.",
    },
    {
      img: prof,
      name: "Rajat Roy",
      rating: 5,
      text: "I recently hired a moving company to help relocate our household items from Mysore to Bangalore. I wanted to express my gratitude for the outstanding, efficient, timely, and professional services they provided. The owner and the team consistently communicated well and fulfilled all their commitments. The entire moving process was stress-free thanks to their assistance. I highly recommend their services to anyone in need of moving help.",
    },
    {
      img: prof,
      name: "Manish Thakur",
      rating: 5,
      text: "Bangalore Packers and Movers Bangalore provided excellent service. The team, composed of skilled professionals, was efficient, careful with belongings, and handled everything professionally. The entire moving process, from packing to unpacking, was seamless and stress-free. I highly recommend their service to anyone looking for reliable movers who prioritize customer satisfaction. Thank you for a job well done, Bangalore Packers and Movers Bangalore!",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="testi-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="testi-title">
              <span>TESTIMONIALS</span>
              <h2>Customer Feedback</h2>
            </div>
          </div>
        </div>
        <div className="row slider">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div className="col-md-6" key={index}>
                <div className="testi-main">
                  <div className="testi-img-text">
                    <img src={testimonial.img} alt={testimonial.name} />
                    <h5>{testimonial.name}</h5>
                    <ul>
                      {Array.from({ length: testimonial.rating }).map(
                        (_, i) => (
                          <li key={i}>
                            <FaStar />{" "}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="testi-text">
                    <p>{testimonial.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
