import React, { useEffect } from "react";
import "./Service.css";
import warehouses from "../Assets/services/warehouse.webp";
import unloading from "../Assets/services/corporate-relocation.webp";
import loading from "../Assets/services/Domestic-Shifting.webp";
import OfficeShifting from "../Assets/services/office-relocation-services.webp";
import hour from "../Assets/services/bike-and-Car-transportation.webp";
import packing from "../Assets/services/local-shifting.webp";
import { Link } from "react-router-dom";
import ClientReviews from "./ClientReviews";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Packing from "./Packing";

const Service = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const reviews = [
    {
      author: "Hiii Frnds",
      content: `Many more packers and movers in Bangalore. When I chose Bangalore Packers And Movers, I felt very good. I directly talked to the branch manager and asked everything; they gave me a proper response. About negative reviews, I asked clearly, most are fake reviews.`,
    },
    {
      author: "SUMIT PANWAR",
      content: `Outstanding and superior service supplier. If you don't think what I say is true, try using Bangalore Packers And Movers packers at least once in your life. After that, you keep using their services for your needs. Regards, group`,
    },
    {
      author: "ANUBHAV SHARMA",
      content:
        "Best service. I have recently shifted from Bangalore to Palacode, Tamil Nadu. Amazing service. Thanks, Bangalore Packers And Movers.",
    },
    {
      author: "MAHESH V",
      content: `greatest business. My friend has already recommended Bangalore Packers And Movers Packers to me when I'm looking for reliable movers and packers in Bangalore. I decided to utilize this packer and mover after receiving the best services possible from them.`,
    },
    {
      author: "RAVI TANDON",
      content:
        "Nice work. Thanks for support and best shifting. Thank you very much.",
    },
    {
      author: "ARIFA SULTANA",
      content:
        "Excellent customer service. Only one word… Safe secure and full responsibility only single name. Bangalore Packers And Movers packers. Thank you very much.",
    },
    {
      author: "SHWETA TRIPATHI",
      content:
        "Bangalore to Noida. Thank you Bangalore Packers And Movers packers for helping me to relocate from Bangalore to Noida. I was very much nervous about shifting but you did very well. Keep it up.",
    },
    {
      author: "RAKESH SHARMA",
      content:
        "Safe shifting. Thanks Suraj. We have shifted from Bangalore to Imphal. We got our goods safe and secure.",
    },
    {
      author: "RANJINI",
      content: "Good service little costly.",
    },
    {
      author: "SUBHAM VERMA",
      content:
        "Best service. I have recently shifted from Bangalore to Palacode, Tamil Nadu. Amazing service. Thanks, Bangalore Packers And Movers.",
    },
    {
      author: "RAMYA D",
      content:
        "Time to time delivery. My bike shifted by Bangalore Packers And Movers. Timely delivery. No any damages. Thank you sir… sure I will refer u.",
    },
    {
      author: "ADITYA",
      content:
        "Nice work. Thanks for support and best shifting. Thank you very Bangalore Packers And Movers packers.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const branchesData = [
    {
      id: 1,
      address:
        "# 35 14th main road, sector 4, HSR Layout, Bangalore karnataka 560102",
      phone: "8884012111",
    },
    {
      id: 2,
      address:
        "112/98 Velankani Drive, Celebrity layout, Electronic city Phase 1, Electronic city, Bengaluru Karnataka 560100",
      phone: "8884012111",
    },
    {
      id: 3,
      address:
        "20ft, Main Road, Magosa Ave, Green Glen Layout, Bellandur, Bengaluru, Karnataka 560103",
      phone: "8884012111",
    },
    {
      id: 4,
      address:
        "18th Cross, 1507, 8th Main Road, Jayanagar 3rd Block, Bengaluru, Karnataka 560011",
      phone: "8884012111",
    },
    {
      id: 5,
      address:
        "9th Cross Rd, SBI Colony, 1st Phase, J. P. Nagar, Bengaluru Karnataka 560078",
      phone: "8884012111",
    },
    {
      id: 6,
      address:
        "55, Manipal Center, 1, Dickinson Road, yellapps Garden, yellappa Chetty Layout, Sivanchetti Gardens, Bengaluru, Karnataka 560042",
      phone: "8884012111",
    },
  ];

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(
          <span key={i} className="star filled">
            &#9733;
          </span>
        );
      } else {
        stars.push(
          <span key={i} className="star">
            &#9733;
          </span>
        );
      }
    }
    return stars;
  };

  return (
    <>
      <section className="banner about-bg">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>Our Services</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link>Our Services</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Packing />

      <div className="service-main">
        <div className="container ">
          <div className="col-md-12">
            <h2 className="title text-center">Our Services</h2>
            <figure className="wp-block-image size-large"></figure>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4 col-sm-12 pull-left">
                      <Link to="/LocalHouseholdShifting">
                        <div className="card">
                          <img
                            src={packing}
                            className="card-img-top"
                            alt="Packing"
                          />
                          <div className="card-body">
                            <h5 className="card-title">
                              Local Household Shifting
                            </h5>
                            <p className="card-text">
                              Bangalore Packers And Movers specialize in local
                              shifting services, ensuring a hassle-free
                              experience for residents moving within the city.
                              With a focus on reliability and efficiency, they
                              offer packing, loading, transportation, and
                              unpacking services tailored to meet individual
                              needs. Their experienced team ensures careful
                              handling of belongings to guarantee a smooth
                              relocation process.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-12 pull-left">
                      <Link to="/DomesticShiftingServices">
                        <div className="card">
                          <img
                            src={loading}
                            className="card-img-top"
                            alt="Loading"
                          />
                          <div className="card-body">
                            <h5 className="card-title">
                              Domestic Shifting Services
                            </h5>
                            <p className="card-text">
                              Bangalore Packers And Movers specialize in
                              seamless Domestic Shifting Services. With years of
                              expertise, we ensure safe and efficient relocation
                              of your belongings across India. Our trained
                              professionals handle packing, loading,
                              transportation, and unpacking with utmost care,
                              ensuring your move is stress-free. Trust us for
                              reliable and affordable domestic shifting
                              solutions tailored to your needs.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-12 pull-left">
                      <Link to="/HouseShiftingServices">
                        <div className="card">
                          <img
                            src={unloading}
                            className="card-img-top"
                            alt="Transportation"
                          />
                          <div className="card-body">
                            <h5 className="card-title">
                              House Shifting Services
                            </h5>
                            <p className="card-text">
                              House Shifting Services by Bangalore Packers and
                              Movers offer seamless, efficient, and stress-free
                              transitions for your business. Our experienced
                              team ensures minimal disruption, handling
                              everything from packing to unpacking. Trust us to
                              manage your office relocation with utmost care and
                              professionalism, guaranteeing a smooth move that
                              allows your business to continue operations
                              swiftly.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-12 pull-left">
                      <Link to="/OfficeShiftingServices">
                        <div className="card">
                          <img
                            src={OfficeShifting}
                            className="card-img-top"
                            alt="Transportation"
                          />
                          <div className="card-body">
                            <h5 className="card-title">
                              Office Shifting Services
                            </h5>
                            <p className="card-text">
                              Looking for reliable office shifting services in
                              Bangalore? Bangalore Packers and Movers specialize
                              in seamless office relocations, ensuring minimal
                              downtime and maximum efficiency. Our professional
                              team handles everything from packing, loading,
                              transportation, to unpacking with utmost care.
                              Trust us to move your office swiftly and securely.
                              Contact us today for a hassle-free moving
                              experience!
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-12 pull-left">
                      <Link to="/CarandBikeTransportation">
                        <div className="card">
                          <img
                            src={hour}
                            className="card-img-top"
                            alt="Transportation"
                          />
                          <div className="card-body">
                            <h5 className="card-title">
                              Car & Bike Transportation
                            </h5>
                            <p className="card-text">
                              Bangalore Packers And Movers specialize in safe
                              and reliable car and bike transportation services.
                              With years of expertise, we ensure secure handling
                              and timely delivery of vehicles to any
                              destination. Our dedicated team uses advanced
                              techniques for hassle-free relocation,
                              prioritizing customer satisfaction. Trust
                              Bangalore Packers And Movers for your vehicle
                              transportation needs with peace of mind.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/* <div className="col-md-4 col-sm-12 pull-left">
                          <Link to="/WarehouseServices">
                            <div className="card">
                              <img
                                src={warehouses}
                                className="card-img-top"
                                alt="Transportation"
                              />
                              <div className="card-body">
                                <h5 className="card-title"> Warehousing</h5>
                                <p className="card-text">
                                  Bangalore Packers and Movers offer reliable
                                  warehouse services for secure storage of your
                                  belongings. Our warehouses are equipped with
                                  advanced security systems to ensure utmost
                                  safety. Whether you need short-term storage
                                  during relocation or long-term warehousing
                                  solutions, we provide flexible options
                                  tailored to your needs. Trust us for efficient
                                  handling and storage of your valuables.
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-main">
        <div className="container pdt10 pdb5">
          <h3 className="title">Packers and movers bangore to other cities</h3>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 ">
                <div className="service-city">
                  <div className="scrollable-container">
                    <ul className="city-list">
                      <li>Packers and Movers Bangalore to Mumbai</li>
                      <li>Packers and Movers Bangalore to Delhi</li>
                      <li>Packers and Movers Bangalore to Pune</li>
                      <li>Packers and Movers Bangalore to Hubli</li>
                      <li>Packers and Movers Bangalore to Goa</li>
                      <li>Packers and Movers Bangalore to Hyderabad</li>
                      <li>Packers and Movers Bangalore to Coimbatore</li>
                      <li>Packers and Movers Bangalore to Chennai</li>
                      <li>Packers and Movers Bangalore to Bhopal</li>
                      <li>Packers and Movers Bangalore to Indore</li>
                      <li>Packers and Movers Bangalore to Ahmedabad</li>
                      <li>Packers and Movers Bangalore to Patna</li>
                      <li>Packers and Movers Bangalore to Madurai</li>
                      <li>Packers and Movers Bangalore to Pondicherry</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="service-city">
                  <div className="scrollable-container">
                    <ul className="city-list">
                      <li>Packers and Movers Bangalore to Cochin</li>
                      <li>Packers and Movers Bangalore to Cuttack</li>
                      <li>Packers and Movers Bangalore to Mangalore</li>
                      <li>Packers and Movers Bangalore to Mysore</li>
                      <li>Packers and Movers Bangalore to Chandigarh</li>
                      <li>Packers and Movers Bangalore to Dehradun</li>
                      <li>Packers and Movers Bangalore to Gurgaon</li>
                      <li>Packers and Movers Bangalore to Guwahati</li>
                      <li>Packers and Movers Bangalore to Kolkata</li>
                      <li>Packers and Movers Bangalore to Lucknow</li>
                      <li>Packers and Movers Bangalore to Rajasthan</li>
                      <li>Packers and Movers Bangalore to Jaipur</li>
                      <li>Packers and Movers Bangalore to Bhubaneswar</li>
                      <li>Packers and Movers Bangalore to Bikaner</li>
                      <li>Packers and Movers Bangalore to Rourkela</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-city">
                  <div className="textwidget custom-html-widget">
                    <div className="scrollable-container">
                      <ul className="city-list">
                        <li>Packers and Movers Bangalore to Vijaywada</li>
                        <li>Packers and Movers Bangalore to Trivandrum</li>
                        <li>Packers and Movers Bangalore to Noida</li>
                        <li>Packers and Movers Bangalore to Ranchi</li>
                        <li>Packers and Movers Bangalore to Shimla</li>
                        <li>Packers and Movers Bangalore to Siliguri</li>
                        <li>Packers and Movers Bangalore to Assam</li>
                        <li>Packers and Movers Bangalore to Gujarat</li>
                        <li>Packers and Movers Bangalore to Kerala</li>
                        <li>Packers and Movers Bangalore to Maharashtra</li>
                        <li>Packers and Movers Bangalore to Visakhapatnam</li>
                        <li>Packers and Movers Bangalore to Andhra Pradesh</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ClientReviews reviews={reviews} sliderSettings={sliderSettings} />

      {/* <div className="table-container">
        <table className="branches-table">
          <thead>
            <tr>
              <th>#</th>
              <th>
                Bangalore Packers And Movers Relocation Pvt Ltd. Branches
                Address
              </th>
              <th>Phone No.</th>
            </tr>
          </thead>
          <tbody>
            {branchesData.map((branch) => (
              <tr key={branch.id}>
                <td>{branch.id}</td>
                <td>{branch.address}</td>
                <td>{branch.phone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </>
  );
};

export default Service;
