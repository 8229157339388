import React from "react";
import { Link } from "react-router-dom";

const Policy = () => {
  return (
    <div>
      <section className="banner-policy">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>Privacy Policy</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Homes</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link to="/services">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="main-privacy ">
        <div className="container">
          <div className="row">
            <h1>Privacy Policy</h1>
            <p>
              At Bangalore Packers and Movers, we value the privacy of our users
              and are committed to protecting it through our website. This
              privacy policy explains how we collect, use, and share the
              personal information you provide to us through our website.
              Information we collect
            </p>
            <p>We may collect the following types of information from you:</p>
            <p>
              Personal information such as your name, email address, phone
              number, and physical
            </p>

            <p>
              Information about your move such as your current address,
              destination address, moving date, and type of move. Other
              information related to your move such as inventory of items,
              packaging requirements, and insurance needs.
            </p>

            <h2>How we share your information</h2>

            <p>
              We may share your personal information with our partners, vendors,
              or service providers who assist us in providing our services to
              you. We may also share your information when required by law, or
              to protect our rights or the rights of others. How we protect your
              information
            </p>

            <p>
              We take reasonable measures to protect your personal information
              from unauthorized access, disclosure, or misuse. However, we
              cannot guarantee the security of your information as no method of
              transmission over the internet or electronic storage is completely
              secure.
            </p>

            <h2>Third-party links</h2>
            <p>
              Our website may contain links to third-party websites. We are not
              responsible for the privacy practices or the content of such
              websites. We encourage you to review the privacy policies of these
              websites before providing any personal information.
            </p>

            <h2>Children’s Privacy</h2>
            <p>
              Our website is not intended for children under the age of 18. We
              do not knowingly collect personal information from children under
              the age of 18.
            </p>

            <h2>Changes to the privacy policy</h2>

            <p>
              We may update this privacy policy from time to time without prior
              notice. We encourage you to review the policy periodically to stay
              informed of any changes.
            </p>

            <p>
              If you have any questions or concerns about our privacy policy,
              please contact us at info@bangalurupackersandmovers.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
