import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import warehouse from "../Assets/Temporary-warehouse.webp";
import house from "../Assets/warehousepng.webp";
import { Link } from "react-router-dom";
import Form from "../Form/Form";

const WarehouseServices = () => {
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="banner-warehouse">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>Warehouse Services</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link>Service</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details-area">
        <div className="container">
          <div className="row mt-3 ">
            <div className="col-lg-7 mt-3">
              <div className="service-details-wrap">
                <h1>The Best Warehouse Services In Bangalore</h1>
                <p className="text-justify">
                  Discover the pinnacle of warehouse services in Bangalore with
                  our expert packers and movers. At Bangalore Packers And
                  Movers, we ensure secure, efficient storage solutions tailored
                  to your needs. Whether you require short-term storage or
                  long-term warehousing, trust our state-of-the-art facilities
                  equipped with advanced security systems. Our team offers
                  meticulous inventory management and round-the-clock
                  surveillance to safeguard your belongings. Experience seamless
                  logistics with our reliable warehouse services, designed to
                  exceed your expectations. Contact us today to learn more about
                  how we can support your storage requirements with precision
                  and care.
                </p>
                <p className="text-justify">
                  Catering to diverse storage needs, Bangalore Packers And
                  Movers provides unmatched warehouse services in Bangalore. Our
                  facilities boast spacious, climate-controlled environments
                  ideal for storing goods of any nature. With a focus on safety
                  and accessibility, we offer advanced inventory tracking and
                  flexible storage options. Whether for residential or
                  commercial purposes, our dedicated team ensures seamless
                  logistics and timely deliveries. Choose reliability and
                  efficiency for your warehousing needs. Contact us now to
                  discover how we can streamline your storage solutions with
                  professionalism and expertise.
                </p>
                <p className="text-justify">
                  Bangalore Packers And Movers stands out for offering the best
                  warehouse services in Bangalore, tailored to meet varied
                  storage requirements. Our facilities feature cutting-edge
                  infrastructure with climate-controlled environments, ensuring
                  optimal conditions for your goods. From secure storage
                  solutions to efficient inventory management, we prioritize
                  safety and accessibility. Whether you're relocating,
                  renovating, or in need of long-term storage, our experienced
                  team ensures seamless operations. Trust us to safeguard your
                  belongings with precision and care, delivering peace of mind
                  throughout the storage process. Contact us today to experience
                  our commitment to excellence.
                </p>

                <div className="single-item">
                  <img
                    src={warehouse}
                    alt="car bike transportation services in bangalore"
                  />
                </div>

                <h2 className="mt-4">
                  Why One Should Hire warehouse services in Bangalore?
                </h2>
                <p className="text-justify">
                  Choosing warehouse services in Bangalore offers a strategic
                  advantage for businesses and individuals alike. Our
                  state-of-the-art facilities ensure secure storage of your
                  goods, safeguarding them from damage and theft. With
                  climate-controlled options available, sensitive items are
                  preserved in optimal conditions. Our expert logistics team
                  ensures efficient inventory management and seamless retrieval,
                  minimizing downtime and optimizing operations. Whether you're
                  storing inventory, equipment, or personal belongings, our
                  warehouse services provide reliability and peace of mind.
                  Trust Bangalore Packers And Movers to safeguard your assets
                  with our trusted warehouse solutions tailored to meet your
                  storage needs.
                </p>
                <p className="text-justify">
                  Our warehouse services in Bangalore are designed to streamline
                  your logistics chain, offering scalable solutions that adapt
                  to your evolving storage requirements. With comprehensive
                  security measures and advanced tracking systems, we ensure
                  transparency and accountability throughout the storage
                  process. Whether you're a business needing space for excess
                  inventory or an individual in transition, our flexible storage
                  options cater to diverse needs. Trust Bangalore Packers And
                  Movers for cost-effective, reliable warehouse solutions that
                  enhance efficiency and protect your valuables.
                </p>
                <p className="text-justify">
                  Our warehouse services in Bangalore are backed by a commitment
                  to excellence and customer satisfaction. We provide
                  customizable storage solutions that align with your budget and
                  timelines, ensuring your goods are stored safely until needed.
                  With convenient access and responsive customer support, we
                  make managing inventory hassle-free. Whether you require
                  short-term storage during a move or long-term solutions for
                  business expansion, our warehouses offer the space and
                  security you can rely on. Choose Bangalore Packers And Movers
                  for unparalleled warehouse services in Bangalore.
                </p>
                <h2>Why Choose Us?</h2>
                <p className="text-justify">
                  Choose Bangalore Packers And Movers for unmatched expertise in
                  relocation services. With years of experience, we ensure
                  seamless moves tailored to your needs. Our dedicated team
                  handles every aspect professionally, from packing to safe
                  delivery. Trust us for punctuality, reliability, and
                  competitive pricing. Whether it's local or interstate,
                  residential or commercial, we prioritize customer
                  satisfaction. Experience stress-free moving with our licensed
                  and insured services. Discover why countless clients prefer us
                  for their relocation needs in Bangalore. Contact us today for
                  a smooth and efficient moving experience.
                </p>
                <p className="text-justify">
                  Count on Bangalore Packers And Movers for your next relocation
                  with confidence. Our commitment to quality shines through
                  personalized attention and careful handling of your
                  belongings. We offer a wide range of services, including
                  packing, unpacking, and secure transportation for households
                  and businesses alike. Benefit from our transparent pricing and
                  clear communication throughout the process. Whether you're
                  moving across town or across the country, our skilled team
                  ensures a hassle-free experience. Choose reliability and
                  excellence—choose Bangalore Packers And Movers for your
                  relocation needs.
                </p>
                <p className="text-justify">
                  Our dedication to customer satisfaction sets us apart. At
                  Bangalore Packers And Movers, we prioritize safety and
                  efficiency. Our trained professionals use quality packing
                  materials to safeguard your items during transit. Enjoy peace
                  of mind knowing that your move is in capable hands. From
                  detailed planning to on-time delivery, we strive for
                  perfection in every move. Discover why we're a trusted choice
                  for residential and commercial relocations in Bangalore.
                  Experience a smooth transition with Bangalore Packers And
                  Movers.
                </p>
              </div>
              <div className="service-sidebar-area m-3 text-center">
                <div className="service-list service-card">
                  <div className="left-main-div">
                    <h3 className="service-details-title">Our Services</h3>

                    <ul>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Local Household Shifting
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Domestic Shifting Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        International Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Car & Bike Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Warehouse Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Packing Unpacking Services
                      </li>
                    </ul>
                  </div>
                  <div className="service-image">
                    <img src={house} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-3">
              <Form activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WarehouseServices;
