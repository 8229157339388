import React, { useState, useEffect } from "react";
import "./Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import pnginter from "../Assets/international.webp";
import international from "../Assets/offc.webp";
import { Link } from "react-router-dom";
import Form from "../Form/Form";
const OfficeShiftingServices = () => {
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section className="banner-international">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>Office Shifting Services</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/services">Service</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-details-area">
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-7 mt-3">
              <div className="service-details-wrap">
                <h1>Office Shifting Services in Bangalore</h1>
                <p className="text-justify">
                  Relocate your office smoothly with our expert office shifting
                  services in Bangalore. At Bangalore Packers And Movers, we
                  understand the importance of minimal downtime and efficient
                  relocation. Our trained professionals handle everything from
                  packing delicate equipment to transporting bulky furniture,
                  ensuring a hassle-free transition. With years of experience
                  and a commitment to quality, trust us to deliver your office
                  belongings safely and on time. Experience seamless office
                  shifting with Bangalore Packers And Movers – your trusted
                  partner in relocation.
                </p>
                <p className="text-justify">
                  Ensure a seamless transition with our specialized office
                  shifting services in Bangalore. Bangalore Packers And Movers
                  offers comprehensive solutions tailored to meet your business
                  needs. From packing and labeling to dismantling and
                  reassembling furniture, our skilled team ensures every detail
                  is handled with care. Minimize disruption and maximize
                  efficiency with our reliable transportation and timely
                  delivery. Trust Bangalore Packers And Movers for a stress-free
                  office relocation experience that exceeds expectations.
                </p>
                <p className="text-justify">
                  Transform your office move into a smooth journey with our
                  dedicated office shifting services in Bangalore. At Bangalore
                  Packers And Movers, we prioritize your business continuity by
                  meticulously planning every aspect of your relocation. Our
                  expert team ensures careful handling of equipment, systematic
                  packing of documents, and secure transportation of furniture.
                  With years of experience and a commitment to excellence, we
                  guarantee minimal downtime and maximum efficiency. Choose
                  Bangalore Packers And Movers for dependable office shifting
                  services that deliver peace of mind, allowing you to focus on
                  what matters most – your business success.
                </p>
                <div className="single-item">
                  <img
                    className="item-img"
                    src={international}
                    alt="Office Shifting Services in Bangalore"
                  />
                </div>

                <h2 className="mt-4">
                  Best Office Shifting Services in Bangalore
                </h2>
                <p className="text-justify">
                  Ensure a seamless transition with our top-rated office
                  shifting services in Bangalore. At Bangalore Packers And
                  Movers, we specialize in relocating businesses with efficiency
                  and care. Our expert team handles every aspect, from packing
                  delicate equipment to timely delivery at your new location.
                  With a focus on minimizing downtime and maximizing
                  convenience, trust us for a hassle-free office move. Discover
                  why businesses across Bangalore rely on our reliable services.
                  Contact us today for a customized moving solution tailored to
                  your office relocation needs.
                </p>
                <p className="text-justify">
                  Experience the ultimate in professionalism and efficiency with
                  Bangalore Packers And Movers' premier office shifting services
                  in Bangalore. Whether you're moving within the city or across
                  states, our dedicated team ensures meticulous planning and
                  execution, handling everything from furniture disassembly to
                  IT equipment setup. We prioritize minimal disruption to your
                  operations, ensuring a swift and smooth transition. Trust our
                  years of expertise and commitment to quality for your next
                  office move. Contact us now to schedule your consultation and
                  discover why we're the preferred choice for businesses in
                  Bangalore.
                </p>
                <p className="text-justify">
                  Transform your office relocation into a seamless experience
                  with Bangalore Packers And Movers' superior office shifting
                  services in Bangalore. Our comprehensive solutions cater to
                  all aspects of your move, from packing and transporting
                  sensitive equipment to setting up your new workspace with
                  precision. With a focus on minimizing downtime and maximizing
                  efficiency, our expert team ensures a stress-free transition
                  for your business. Count on us for reliable, timely service
                  backed by years of industry expertise. Contact us today to
                  discuss your office moving needs and discover why we're the
                  trusted partner for corporate relocations across Bangalore.
                </p>
                <h4>Why Choose Us?</h4>
                <p className="text-justify">
                  Choose Bangalore Packers And Movers for unparalleled expertise
                  in seamless relocation solutions. With years of trusted
                  service, we prioritize customer satisfaction through
                  meticulous planning and efficient execution. Our skilled team
                  ensures your belongings are handled with utmost care,
                  guaranteeing a stress-free move every time. We offer
                  competitive pricing without compromising on quality, backed by
                  transparent policies and timely delivery. Whether you're
                  moving locally or across the country, rely on us for a smooth
                  transition to your new destination.
                </p>
                <p className="text-justify">
                  Discover why Bangalore Packers And Movers stands out for your
                  relocation needs. We bring extensive experience and a
                  commitment to excellence, ensuring a hassle-free move. Our
                  dedicated team employs advanced packing techniques and modern
                  equipment, coupled with personalized customer service. From
                  residential to commercial moves, we deliver reliability and
                  punctuality, backed by competitive rates and comprehensive
                  insurance coverage. Trust us to handle your move with
                  professionalism and care, making your transition effortless
                  and secure.
                </p>
                <p className="text-justify">
                  Bangalore Packers And Movers distinguishes itself with a
                  proven track record of reliability and efficiency in
                  relocation services. Our team of skilled professionals ensures
                  meticulous planning and execution tailored to your specific
                  needs. We prioritize safety and security, using high-quality
                  packing materials and advanced techniques. With transparent
                  communication and competitive pricing, we guarantee a seamless
                  moving experience. Choose us for peace of mind and a smooth
                  transition to your new home or office.
                </p>
                <div className="service-sidebar-area m-3 text-center">
                  <div className="service-list service-card">
                    <div className="left-main-div">
                      <h3 className="service-details-title">Our Services</h3>

                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faCheck} />
                          Local Household Shifting
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faCheck} />
                          Domestic Shifting Services
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faCheck} />
                          International Transportation
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faCheck} />
                          Car & Bike Transportation
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faCheck} />
                          Warehouse Services
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faCheck} />
                          Packing Unpacking Services
                        </li>
                      </ul>
                    </div>
                    <div className="service-image">
                      <img
                        style={{ width: "200px", height: "auto" }}
                        src={pnginter}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-3">
              <Form activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OfficeShiftingServices;
